import { createModel } from './model';
import API from '../../api';

export const useAuth = createModel({
	user: null,
	logged: false,
	code: '',
	check: async function() {
		const auth = localStorage.getItem('auth');
		if (auth) {
			const {code, email} = JSON.parse(auth);
			// const r = await API.call('auth/auth', {code, email});
			// if (r.logged) {
				this.logged = true;
				this.code = code;
			//}
		}
	},
	login: async function(code: string, email: string) {
		const r = await API.call('auth/auth', {code, email});

		if (r.logged) {
			this.logged = true;
			this.code = code;
			localStorage.setItem('auth', JSON.stringify({code, email}));
		}

		return r;
	}
}, { debug: false });