import { useEffect, useRef } from 'react';
import { useWavesurfer} from '@wavesurfer/react';
import anime from 'animejs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons'

export function AudioPlayer({title, url, delay, width = 300, height = 60, autoplay = true}) {
	const divRef = useRef(null);
	const playRef = useRef(null);
	const waveRef = useRef(null);

	const { wavesurfer, isReady, isPlaying, currentTime } = useWavesurfer({
		container: waveRef,
		width: width,
		height: height,
		normalize: true,
		fillParent: true,
		waveColor: '#cacaca',
		progressColor: '#4968f0',
		cursorColor: '#4a69f0',
		cursorWidth: 0,
		barWidth: 3,
		barGap: 2,
		barRadius: 28,
		barHeight: 0.8,
		backend: 'MediaElement',
		responsive: true,
		url: url
	});

	useEffect(() => {
		setTimeout(() => {
			anime({
				targets: divRef.current,
				opacity: 1,
				duration: 1000,
			});
			divRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
			setTimeout(() => {
				if (autoplay) {
					console.log('- autoplay', playRef.current);
					const clickEvent = new MouseEvent('click', {
						view: window,
						bubbles: true,
						cancelable: true,
					});
					playRef.current.dispatchEvent(clickEvent);
				}
			}, 500);
		}, delay || 0);
	}, []);

	function play() {
		wavesurfer && wavesurfer.play();
	}

	function pause() {
		wavesurfer && wavesurfer.pause();
	}

	return (
		<div className="player" ref={divRef}>
			<div className="section-title">{title}</div>
			<div className="section-content">
				{isPlaying ? <FontAwesomeIcon onClick={pause} icon={faPause} size="3x" fixedWidth /> : <FontAwesomeIcon ref={playRef} onClick={play} icon={faPlay} size="3x" fixedWidth /> }
				<div className="ws" ref={waveRef} />
			</div>
		</div>
	)
}