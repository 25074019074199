import { useEffect, useRef } from 'react';
import { useWavesurfer} from '@wavesurfer/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPause, faPlay, faDownload } from '@fortawesome/free-solid-svg-icons'

export function AudioPlayerClean({url, width = 600, height = 60, download=true}) {
	const divRef = useRef(null);
	const waveRef = useRef(null);

	const { wavesurfer, isReady, isPlaying, currentTime } = useWavesurfer({
		container: waveRef,
		width: width,
		height: height,
		normalize: true,
		fillParent: true,
		waveColor: '#cacaca',
		progressColor: '#4968f0',
		cursorColor: '#4a69f0',
		cursorWidth: 0,
		barWidth: 3,
		barGap: 2,
		barRadius: 28,
		barHeight: 0.8,
		backend: 'MediaElement',
		url: url
	});

	function play() {
		wavesurfer && wavesurfer.play();
	}

	function pause() {
		wavesurfer && wavesurfer.pause();
	}

	return (
		<div className="clean-player">
			{isPlaying ? <FontAwesomeIcon onClick={pause} icon={faPause} size="3x" fixedWidth /> : <FontAwesomeIcon onClick={play} icon={faPlay} size="3x" fixedWidth /> }
			<div className="ws" ref={waveRef} />
			{download && (
				<a className="download-link" href={url} _target="blank">
					<FontAwesomeIcon icon={faDownload} size="3x" fixedWidth />
				</a>
			)}
		</div>
	)
}