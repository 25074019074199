import { useEffect, useRef } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import anime from 'animejs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'

export function Midi({ title, url, delay }) {
	const divRef = useRef(null);

	useEffect(() => {
		setTimeout(() => {
			anime({
				targets: divRef.current,
				opacity: 1,
				duration: 1000,
			});
			divRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest'});
		}, delay || 0);
	}, []);

	return (
		<div className="midi" ref={divRef}>
			<div className="section-title">{title}</div>
			<div className="section-content">
				<img src={url} />
			</div>
		</div>
	)
}