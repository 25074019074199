import { useEffect, useState, useRef } from 'react';
import API from '../../api';
import Select from 'react-select';

import './demo.less';
import './adaptive.less';

// components
import { AudioPlayer } from '../audioplayer';
import { AudioPlayerClean } from '../audioplayerclean';
import { Rights } from '../rights';

export function Adaptive() {
	const [pageWidth, setPageWidth] = useState(window.innerWidth);

	const versions = [
		{ label: 'Full', path: '/audio/BigBreakDemoSource.mp3' },
		{ label: '15s Cutdown', path: '/audio/BigBreakDemo_action.mp3', width: 350 },
		{ label: 'Ambient', path: '/audio/BigBreakDemo_ambient_full.mp3' },
		{ label: 'Sparse', path: '/audio/BigBreakDemoSparse.mp3' },
		{ label: 'Banjo Replacement', path: '/audio/BigBreakDemo_Banjo2.mp3' },
		{ label: 'Jazz Remix', path: '/audio/polymuse-csm-110bpm-jazzpiano.mp3' },
	];

	const rights = [
		['Extreme Music', 100]
	];

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		}
	}, []);

	function handleResize() {
		setPageWidth(window.innerWidth);
	}

	return (
		<div className={'adaptive demo-page' + (pageWidth < 769 ? ' mobile' : '')}>
			<div className="example">
				<b>Example Use Case</b>: An ad agency found the perfect song for their new spot, but wants to try out different versions with the client.
			</div>
			<div key={0} className="generation section with_image">
				<div className="section-title">Full Mix & Pattern Recognition</div>
				<div className="section-content">
					<AudioPlayerClean url={versions[0].path} download={false} width={pageWidth < 769 ? 250 : 655} />
					<img src="/images/patterns.png" />
				</div>
			</div>
			{versions.slice(1).map((v, i) => (
				<div key={i} className="section generation" style={{ width: pageWidth > 769 ? (v.width + 100 || 'auto') : 'auto' }}>
					<AudioPlayer key={i} title={v.label} url={v.path} width={pageWidth < 769 ? 300 : (v.width || 665)} height={60} autoplay={false} />
				</div>
			))}
			{false && <Rights title="Collection Rights on New Songs" delay={0} rights={rights} showExtra={false} />}
		</div>
	);
}