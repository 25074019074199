import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { createBrowserRouter } from 'react-router-dom';

// auth
import { useAuth } from './models/auth';

// pages
import { AuthPage } from '../components/pages/authPage';
import { Home } from '../components/pages/home';
import { Demo } from '../components/pages/demo';
import { Delphos } from '../components/pages/delphos';
import { Derivative } from '../components/pages/derivative';
import { Adaptive } from '../components/pages/adaptive';
import { AudioGuard } from '../components/pages/audioguard';
import { Translation } from '../components/pages/translation';
import { MainLayout } from '../components/pages/mainLayout';

const router = createBrowserRouter([
	{
		path: '/',
		element: <MainLayout />,
		children: [
			{ path: '/', element: <Home /> },
			{ path: '/demo', element: <Demo /> },
			{ path: '/derivative', element: <Derivative /> },
			{ path: '/delphos', element: <Delphos /> },
			{ path: '/adaptive', element: <Adaptive /> },
			{ path: '/audioguard', element: <AudioGuard /> },
			{ path: '/translation', element: <Translation /> },
		],
	},
]);


function Site() {
	const auth = useAuth().watch(['logged']);

	useEffect(() => {
		auth.check();
	}, []);


	return (
		<>
			{!auth.logged && <AuthPage />}
			{auth.logged && <RouterProvider router={router} />}
		</>
	);
}


const container = document.getElementById("app");
const root = createRoot(container)
root.render(<Site />);
