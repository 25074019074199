import './home.less';

export function Home() {
	return (
		<div className="holder">
			<div id="musaic-logo">
				<img src="/images/Logo.svg" />
			</div>
		</div>
	);
}