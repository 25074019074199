import { useEffect, useState, useRef } from 'react';
import API from '../../api';
import Select from 'react-select';

import './demo.less';
import './audioguard.less';

// components
import { AudioPlayer } from '../audioplayer';
import { AudioPlayerClean } from '../audioplayerclean';

export function Translation() {
	const [pageWidth, setPageWidth] = useState(window.innerWidth);

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		}
	}, []);

	function handleResize() {
		setPageWidth(window.innerWidth);
	}

	return (
		<div className={'audioguard demo-page' + (pageWidth < 769 ? ' mobile' : '')}>
			<div className="section">
				<div className="section-title"><b>Vocals Translation</b></div>
				<div className="section-content">
					<h2>Original Audio</h2>
					<AudioPlayerClean url={'/audio/lola.mp3'} download={false} width={pageWidth < 769 ? 250 : 685} />
					<br/>
					<h2>Transcription</h2>
					<p>You're a man without a backbone<br />
						I see you're looking for a window<br />
						You really think you're something special<br />
						And think you're hot but acting so cold<br />
						That rock 'n rock don't really move my soul<br />
						You're a budget Elvis Costello<br />
						Baby, you deserve a medal<br />
						For being number one asshole<br />
<br />
						Even on the cover of GQ<br />
						I am never going home with you<br />
						A leather jacket don't impress me<br />
						I'm not a fool<br />
						I'm kinda different to the girl-next-door<br />
						I'm looking for something more<br />
						You're barking up the wrong tree<br />
					...
					</p>
					<br/>
					<h2>Translated Audio</h2>
					<AudioPlayerClean url={'/audio/lolacoca_1125_GQ_mandarin_demo.mp3'} download={false} width={pageWidth < 769 ? 250 : 685} />
					<br/>
					<h2>Translation</h2>
					<p>你是一个没有力量的男人<br />
						我看见你在寻找出路<br />
						你以为自己是个特别男人<br />
						觉得你很酷,但你不是<br />
						你是摇滚乐,我动不了你<br />
						你是个廉价的猫王猫王 科斯特罗<br />
						宝贝,你应该得到奖品<br />
						做一个混蛋。<br />
						即使你上了GQ的封面<br />
						我永远不会跟你回家<br />
						你的风格不让我印象深刻<br />
						我不是傻瓜<br />
						我和隔壁那个女孩不一样<br />
						我在找更多东西<br />
						跟我在一起你没有运气的<br />
						女孩,请,女孩<br />
						让我来<br />
						...
					</p>					
				</div>
			</div>
		</div>
	);
}