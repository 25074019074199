import { useEffect, useRef } from 'react';
import rough from 'roughjs';
import anime from 'animejs';

/**
 * Component to draw a "hand drawn" looking arrow from one spot to another using roughjs
 */
export function Arrow({height, width, angle, duration, delay, title}) {
	const canvasRef = useRef(null);
	const titleRef = useRef(null);
	const intRef = useRef(null);
	const progressRef = useRef(0);
	const speed = 50; // ms

	useEffect(() => {
		setTimeout(() => {
			intRef.current = setInterval(() => {
				drawArrow(angle, progressRef.current);
				progressRef.current += (speed / duration);
				// stop once we hit 1
				if (progressRef.current > 1) {
					clearInterval(intRef.current);
					anime({
						targets: titleRef.current,
						opacity: 1,
						duration: 2000,
					});
				}
			}, speed);
		}, delay);

		return () => {
			clearInterval(intRef.current);
		}
	}, []);

	/**
	 * clears the canvas and draws an arrow given the angle and percent completion
	 * @param {int} angle the angle in degrees the arrow should point
	 * @param {float} progress the percent completion of the arrow between 0 and 1
	 */
	function drawArrow(angle, progress) {
		//console.log('- drawing arrow at angle:', angle, 'progress:', progress);

		// clear the canvas
		const canvas = canvasRef.current;
		const context = canvas.getContext('2d');
		context.clearRect(0, 0, canvas.width, canvas.height);

		// find center
		const center = {x: width / 2, y: height / 2};
		// convert to radians
		const rads = angle * Math.PI / 180;
		// find start (always the same, regardless of progress)
		const start = {
			x: center.x - Math.cos(rads) * width  / 2,
			y: center.y + Math.sin(rads) * height / 2,
		};
		// find the end point (with respect to progress)
		const end = {
			x: center.x + Math.cos(rads) * width * (progress - 0.5),
			y: center.y - Math.sin(rads) * height * (progress - 0.5),
		};

		// now to draw the arrow head
		const headLength = Math.min(width / 8, 30);
		const headAngle = 25;
		const angle1 = rads + Math.PI + headAngle * Math.PI / 180;
		const angle2 = rads + Math.PI - headAngle * Math.PI / 180;
		const head1 = {
			x: end.x + Math.cos(angle1) * headLength,
			y: end.y - Math.sin(angle1) * headLength,
		};
		const head2 = {
			x: end.x + Math.cos(angle2) * headLength,
			y: end.y - Math.sin(angle2) * headLength,
		};

		//console.log('- start', start, '- end', end);

		const rc = rough.canvas(canvas);
		rc.line(start.x, start.y, end.x, end.y, {
			stroke: '#475EC6',
			strokeWidth: 2,
			roughness: 3,
			bow: 6,
		});
		rc.line(end.x, end.y, head1.x, head1.y, {
			stroke: '#475EC6',
			strokeWidth: 2,
			roughness: 2,
			bow: 6,
		});
		rc.line(end.x, end.y, head2.x, head2.y, {
			stroke: '#475EC6',
			strokeWidth: 2,
			roughness: 2,
			bow: 6,
		});
	}

	return (
		<>
			<div className="arrow-title-holder" style={{
				width: width,
				height: height,
				transform: `rotate(${angle > 180 ? 180 - angle : -angle}deg)`,
			}}>
				{title && <div className="arrow-title" ref={titleRef} >{title}</div>}
			</div>
			<canvas
				id="arrow"
				width={width}
				height={height}
				ref={canvasRef}
			/>
		</>
	)
}