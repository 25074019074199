import { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faRefresh, faBolt, faCog } from '@fortawesome/free-solid-svg-icons'
import API from '../../api';
import Select from 'react-select';

import './demo.less';
import './derivative.less';

// components
import { AudioPlayerClean } from '../audioplayerclean';
import { DerivativeAlbum } from '../derivativeAlbum';
import { Rights } from '../rights';

export function Derivative() {
	const [pageWidth, setPageWidth] = useState(window.innerWidth);
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);
	const [processing, setProcessing] = useState(false);
	const [generations, setGenerations] = useState([]);
	const genRef = useRef(generations);
	const genDirtyRef = useRef(false);

	const rights = [
		['Songs To Your Eyes', 100]
	];

	useEffect(() => {
		getData();
		const int = setInterval(checkGenerations, 5000);
		window.addEventListener('resize', handleResize);

		return () => {
			clearInterval(int);
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	function handleResize() {
		setPageWidth(window.innerWidth);
	}

	async function getData() {
		const data = await API.call('musaic/getDerivativeData');
		setData(data);
		setLoading(false);
	}

	async function checkGenerations() {
		if (genRef.current.length === 0) {
			//console.log('- no gens, returning');
			return;
		}
		const newGensPromises = genRef.current.map(async (gen) => {
			if (!gen.ready && !gen.error) {
				const data = await API.call('delphos/derivativeReady', { id: gen.id });
				
				// merge this data with the gen object
				let oldgen = { ...gen};
				gen = { ...gen, ...data };
				if (oldgen.ready ?? false != gen.ready ?? false || oldgen.error ?? false != gen.error ?? false) {
					console.log('- gen updated:', gen);
				}
				return gen;
			} else {
				return gen;
			}
		});
		const newGens = await Promise.all(newGensPromises);
		if (!genDirtyRef.current) {
			//console.log('- setting new gens', newGens, 'old was', genRef.current);
			genRef.current = newGens;
			setGenerations(genRef.current);
		}
		genDirtyRef.current = false;
	}

	async function query() {
		if (processing) return;
		const world = 'MoontribeElectroPop';
		setProcessing(true);
		const data = await API.call('delphos/derivative', { world });
		data.world = world;
		console.log(data);
		genDirtyRef.current = true;
		// add data to generations
		genRef.current = [...genRef.current, data];
		setGenerations(genRef.current);
		setProcessing(false);
	}

	return (
		<div className={'derivative demo-page' + (pageWidth < 769 ? ' mobile' : '')}>
			{loading && (
				<FontAwesomeIcon icon={faRefresh} size="2x" className="fa-spin" fixedWidth />
			)}
			{!loading && (
				<>
					<div className="example">
						<b>Example Use Case</b>: Electronic music artist wants to expand their catalog quickly and at a high quality to help reach a wider audience, demonstrate versatility, and appeal to more listeners.
					</div>
					<DerivativeAlbum album={data.album} tracks={data.tracks} pageWidth={pageWidth} />
					<div id="derivative-controls" className="section">
						<div className="section-content" onClick={query}>Generate Derivatives <FontAwesomeIcon icon={processing ? faCog : faBolt} className={processing ? 'fa-spin' : ''} fixedWidth /></div>
					</div>
				</>
			)}
			{[...generations].sort((a, b) => b - a).map((gen, i) => (
				<div key={i} className="generation section">
					<div className="section-title">Artist: Moontribe, Genre: Electropop, Gen ID: {gen.id}</div>
					<div className="section-content">
						{!gen.ready && !gen.error && (
							<>
								Processing...
								<FontAwesomeIcon icon={faRefresh} size="2x" className="fa-spin" fixedWidth />
							</>
						)}
						{gen.ready && (
							<AudioPlayerClean url={gen.url} width={pageWidth < 769 ? 300 : 665} height={60} />
						)}
						{gen.error && (
							<>
								Error: <pre>{gen.comment}</pre>
							</>
						)}
					</div>
				</div>
			))}
			{generations.filter(gen => gen.ready).length > 0 && (
				<Rights title="Collection Rights on New Songs" delay={0} rights={rights} showExtra={false} />
			)}
		</div>
	);
}