import { useEffect, useState, useRef } from 'react';
import API from '../../api';
import Select from 'react-select';

import './demo.less';
import './audioguard.less';

// components
import { AudioPlayer } from '../audioplayer';
import { AudioPlayerClean } from '../audioplayerclean';

export function AudioGuard() {
	const [pageWidth, setPageWidth] = useState(window.innerWidth);

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		}
	}, []);

	function handleResize() {
		setPageWidth(window.innerWidth);
	}

	return (
		<div className={'audioguard demo-page' + (pageWidth < 769 ? ' mobile' : '')}>
			<div className="section">
				<div className="section-title"><b>AudioGuard Samples</b></div>
				<div className="section-content">
					<p>These are very early alpha samples of our AudioGuard outputs. We're still working on a few equalization issues (you'll notice that the guarded version is slightly lower level), but we've stomped out most artifacts. When you're listening to guarded continuations, there's three main effects:
					</p>
					<ul>
						<li>The overall quality becomes more muddy and distant. It can be hard to notice sometimes because regular generations also have that feeling sometimes, but we've noticed a distinct effect across continuations.</li>
						<li>The continuation will pick up elements of the guard signal and produce a buzz. This is present in almost every continuation.</li>
						<li>The continuation will latch onto the guard signal as the main element, and spiral out. This is rarer, but more dramatic. Continuation 2 of "About To Go Down" is an example of this.</li>
					</ul>
					<p>While reviewing the examples, you should know that the guard signal effect is like a snowball rolling down a hill. It takes about 15s for the continuation to start being noticeably corrupted, and it gets worse with time.
					</p>
				</div>
			</div>
			<div className="section">
				<div className="section-title"><b>Example 1: Erase</b></div>
				<div className="section-content">
					<h2>Full Source</h2>
					<AudioPlayerClean url={'/audio/STYE955_001 Erase 140Bpm F.wav'} download={false} width={pageWidth < 769 ? 250 : 685} />
					<br/>
					<h2>Full Source Guarded</h2>
					<AudioPlayerClean url={'/audio/STYE955_001 Erase 140Bpm F_guarded.wav'} download={false} width={pageWidth < 769 ? 250 : 685} />
					<br/>
				</div>
			</div>
			<div className="section">
				<div className="section-title">Continuation 1</div>
				<div className="section-content">
					<p>Continuations are run using AudioCraft, and taking 15s segments as the seed. While neither continuation is good music, you can clearly hear the difference in "clean" sound from the source continuation to the guarded version:
					</p>
					<br/>
					<h2>Continuation From Source Segment 5</h2>
					<AudioPlayerClean url={'/audio/STYE955_001 Erase 140Bpm F_5_cont0.wav'} download={false} width={pageWidth < 769 ? 250 : 685} />
					<br />
					<h2>Continuation From Guarded Segment 5</h2>
					<AudioPlayerClean url={'/audio/STYE955_001 Erase 140Bpm F_guarded_seg_5_cont0.wav'} download={false} width={pageWidth < 769 ? 250 : 685} />
					<br />
				</div>
			</div>
			<div className="section">
				<div className="section-title">Continuation 2</div>
				<div className="section-content">
					<p>The continuations get disrupted in a few different ways. The most common method is this one, where a "buzz" starts to generate around 10-20s, and eventually takes over the song. This effect is very common in our results.
					</p>
					<br />
					<h2>Continuation From Source Segment 8</h2>
					<AudioPlayerClean url={'/audio/STYE955_001 Erase 140Bpm F_8_cont1.wav'} download={false} width={pageWidth < 769 ? 250 : 685} />
					<br />
					<h2>Continuation From Guarded Segment 8</h2>
					<AudioPlayerClean url={'/audio/STYE955_001 Erase 140Bpm F_nogain_8_cont1.wav'} download={false} width={pageWidth < 769 ? 250 : 685} />
					<br />
				</div>
			</div>
			<div className="section">
				<div className="section-title"><b>Example 2: About To Go Down</b></div>
				<div className="section-content">
					<h2>Full Source</h2>
					<AudioPlayerClean url={'/audio/STYE921_007 About To Go Down 96Bpm F - Instrumental Mix.wav'} download={false} width={pageWidth < 769 ? 250 : 685} />
					<br />
					<h2>Full Source Guarded</h2>
					<AudioPlayerClean url={'/audio/STYE921_007 About To Go Down 96Bpm F - Instrumental Mix_nogain.wav'} download={false} width={pageWidth < 769 ? 250 : 685} />
					<br />
				</div>
			</div>
			<div className="section">
				<div className="section-title">Continuation 1</div>
				<div className="section-content">
					<h2>Continuation From Source Segment 3</h2>
					<AudioPlayerClean url={'/audio/STYE921_007 About To Go Down 96Bpm F - Instrumental Mix_3_cont1.wav'} download={false} width={pageWidth < 769 ? 250 : 685} />
					<br />
					<h2>Continuation From Guarded Segment 3</h2>
					<AudioPlayerClean url={'/audio/STYE921_007 About To Go Down 96Bpm F - Instrumental Mix_nogain_3_cont0.wav'} download={false} width={pageWidth < 769 ? 250 : 685} />
					<br />
				</div>
			</div>
			<div className="section">
				<div className="section-title">Continuation 2</div>
				<div className="section-content">
					<h2>Continuation From Source Segment 8</h2>
					<AudioPlayerClean url={'/audio/STYE921_007 About To Go Down 96Bpm F - Instrumental Mix_8_cont0.wav'} download={false} width={pageWidth < 769 ? 250 : 685} />
					<br />
					<h2>Continuation From Guarded Segment 8</h2>
					<AudioPlayerClean url={'/audio/STYE921_007 About To Go Down 96Bpm F - Instrumental Mix_nogain_8_cont0.wav'} download={false} width={pageWidth < 769 ? 250 : 685} />
					<br />
				</div>
			</div>
		</div>
	);
}