import { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faRefresh } from '@fortawesome/free-solid-svg-icons'
import API from '../../api';
import Select from 'react-select';

import './demo.less';
import './delphos.less';

// components
import { AudioPlayerClean } from '../audioplayerclean';

export function Delphos() {
	const [loading, setLoading] = useState(true);
	const [prompts, setPrompts] = useState([]);
	const [list, setList] = useState([]);
	const [world, setWorld] = useState(null);
	const [generations, setGenerations] = useState([]);
	const genRef = useRef(generations);
	const genDirtyRef = useRef(false);

	useEffect(() => {
		getList();
	}, []);

	async function checkGenerations() {
		if (genRef.current.length === 0) {
			//console.log('- no gens, returning');
			return;
		}
		const newGensPromises = genRef.current.map(async (gen) => {
			if (!gen.ready && !gen.error) {
				const data = gen.world == 'MoontribeElectroPop' ? await API.call('delphos/derivativeReady', { id: gen.id }) : await API.call('delphos/ready', { id: gen.id });
				
				// merge this data with the gen object
				let oldgen = { ...gen};
				gen = { ...gen, ...data };
				if (oldgen.ready ?? false != gen.ready ?? false || oldgen.error ?? false != gen.error ?? false) {
					console.log('- gen updated:', gen);
				}
				return gen;
			} else {
				return gen;
			}
		});
		const newGens = await Promise.all(newGensPromises);
		if (!genDirtyRef.current) {
			//console.log('- setting new gens', newGens, 'old was', genRef.current);
			genRef.current = newGens;
			setGenerations(genRef.current);
		}
		genDirtyRef.current = false;
	}

	async function getList() {
		const data = await API.call('delphos/list');
		
		const list = Object.entries(data.soundworlds).map((world, i) => ({
			value: world[0],
			label: world[1].name_pretty,
		}));
		list.push({ value: 'MoontribeElectroPop', label: 'MoontribeElectroPop' });
		setList(list);
		setWorld(list[0]);
		setLoading(false);
		checkGenerations();
		setInterval(checkGenerations, 5000);
	}

	async function listChange(option) {
		setWorld(option.value);
	}

	async function query() {
		console.log('- world:', world);
		setLoading(true);
		const data = world == 'MoontribeElectroPop' ? await API.call('delphos/derivative', { world }) : await API.call('delphos/query', { world });
		
		data.world = world;
		console.log(data);
		genDirtyRef.current = true;
		// add data to generations
		genRef.current = [...genRef.current, data];
		setGenerations(genRef.current);
		setLoading(false);
	}

	return (
		<div className="delphos demo-page">
			<div id="generator" className="section">
				<div className="section-title">generator</div>
				<div className="section-content">
					<Select
						styles={{
							control: (base, state) => ({
								...base,
								borderColor: '#000',
							})
						}}
						theme={(theme) => ({
							...theme,
							colors: {
								...theme.colors,
								primary: '#999',
							}
						})}
						selectedOption={list[0]}
						options={list}
						onChange={listChange}
					/>
					{!loading ? <FontAwesomeIcon onClick={query} icon={faPlay} size="2x" fixedWidth /> : <FontAwesomeIcon icon={faRefresh} size="2x" className="fa-spin" fixedWidth />}
				</div>
			</div>
			{[...generations].sort((a, b) => b - a).map((gen, i) => (
				<div key={i} className="generation section">
					<div className="section-title">{(gen.world ? gen.world + ': ' : '') + gen.id}</div>
					<div className="section-content">
						{!gen.ready && !gen.error && (
							<>
								Processing...
								<FontAwesomeIcon icon={faRefresh} size="2x" className="fa-spin" fixedWidth />
							</>
						)}
						{gen.ready && (
							<AudioPlayerClean url={gen.url} />
						)}
						{gen.error && (
							<>
								Error: <pre>{gen.comment}</pre>
							</>
						)}
					</div>
				</div>
			))}
		</div>
	);
}