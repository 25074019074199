import { useEffect, useRef } from 'react';
import anime from 'animejs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'

export function Rights({ delay, rights, title = null, showExtra = true}) {
	const divRef = useRef(null);

	useEffect(() => {
		setTimeout(() => {
			anime({
				targets: divRef.current,
				opacity: 1,
				duration: 1000,
			});
			divRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
		}, delay || 0);
	}, []);

	return (
		<div className="rights section" ref={divRef}>
			<div className="section-title">{title || 'Collection Rights on New Song'}</div>
			<div className="section-content">
				<div className="rights-section">
					<b>Master</b><br />
					{rights.map((pub, i) => (
						<li key={'pub' + i}> {pub[1]}% {pub[0]}</li>
					))}
					{showExtra && (<li> 25% User </li>)}
					{showExtra && (<li> 25% SongLab admin </li>)}
				</div>
				<div className="rights-section">
					<b>Composition</b><br />
					{rights.map((pub, i) => (
						<li key={'pub' + i}> {pub[1]}% {pub[0]}</li>
					))}
					{showExtra && (<li> 25% User </li>)}
					{showExtra && (<li> 25% SongLab admin </li>)}
				</div>
			</div>
		</div>
	)
}