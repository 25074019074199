import { useEffect, useState } from 'react';
import API from '../../api';

// styling
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faRefresh } from '@fortawesome/free-solid-svg-icons'
import './demo.less';

// components
import { Arrow } from '../arrow';
import { Seeds } from '../seeds';
import { AudioPlayer } from '../audioplayer';
import { Rights } from '../rights';
import { Midi } from '../midi';
import { get } from 'animejs';

export function Demo() {
	const [pageWidth, setPageWidth] = useState(window.innerWidth);
	const [started, setStarted] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [data, setData] = useState(null);
	const [prompts, setPrompts] = useState([]);
	const [prompt, setPrompt] = useState(null);
	const [rights, setRights] = useState([]);
	const speedUp = 0.6;

	const desktopPositions = {
		choosePrompt: { x: 360, y: 20, size: 200, angle: 200, delay: 0 },
		pressThis: { x: 360, y: 160, size: 200, angle: 35, delay: 0 },
		prompt: { x: 100, y: 100 },
		promptToSeed: { x: 310, y: 160, size: 150, angle: 250, delay: 0 },
		seed: { x: 150, y: 300, delay: 1000 * speedUp },
		seedToMidi: { x: 420, y: 170, size: 300, angle: 35, delay: 3000 * speedUp },
		midi: { x: 700, y: 100, delay: 4500 * speedUp},
		seedToRender: { x: 420, y: 220, size: 450, angle: 12, delay: 6000 * speedUp },
		midiToAudio: { x: 800, y: 270, size: 200, angle: 260, delay: 6300 * speedUp },
		audio: { x: 700, y: 500, delay: 7500 * speedUp },
		seedToRights: { x: 370, y: 500, size: 300, angle: -40, delay: 8000 * speedUp },
		rights: { x: 700, y: 660, delay: 8500 * speedUp },
	};

	const mobilePositions = {
		choosePrompt: { x: 180, y: 120, size: 200, angle: 200, delay: 0 },
		pressThis: { x: 180, y: 260, size: 200, angle: 35, delay: 0 },
		prompt: { x: 10, y: 200 },
		promptToSeed: { x: 180, y: 260, size: 195, angle: 250, delay: 0 },
		seed: { x: 10, y: 450, delay: 1000 * speedUp},
		seedToMidi: { x: 0, y: 570, size: 200, angle: -70, delay: 3000 * speedUp },
		midi: { x: 10, y: 780, delay: 4500 * speedUp},
		seedToRender: { x: 0, y: 580, size: 450, angle: 240, delay: 6000 * speedUp },
		midiToAudio: { x: 0, y: 960, size: 200, angle: -60, delay: 6300 * speedUp },
		audio: { x: 10, y: 1150, delay: 7500 * speedUp },
		seedToRights: { x: -160, y: 610, size: 860, angle: 260, delay: 9000 * speedUp },
		rights: { x: 10, y: 1460, delay: 9500 * speedUp },
	};

	const positions = pageWidth > 768 ? desktopPositions : mobilePositions;

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		getData();
		window.scrollTo(0, 0);

		return () => {
			window.removeEventListener('resize', handleResize);
		}
	}, []);

	function handleResize() {
		setPageWidth(window.innerWidth);
	}

	async function getData() {
		const data = await API.call('musaic/getDemoData');

		try {
			setData(data);
			getPromptsFromData(data);
			getRightsFromData(data);
			setLoaded(true);
		} catch(e) {
			console.error('error getting data:', e, r);
		}
		//console.log(data);
	}

	function getPromptsFromData(data) {
		if (!data) return [];
		const prompts = Object.entries(data).map((gen, i) => ({
			value: gen[0],
			label: gen[1].prompt,
		}));
		setPrompts(prompts);
	}

	function getRightsFromData(data) {
		if (!data) return [];
		const rights = {}; // we want a rights entry for each song key
		for (const [key, gen] of Object.entries(data)) {
			// lets get all the publishers and add up their percents
			const cats = {};
			for (const seed of gen.seed_tracks) {
				// get the catalog name of the track
				seed.catalog.name = seed.catalog.name.replace(/(MUSIC|Music|Publishing).*/, '');
				seed.catalog.name = seed.catalog.name.replace(/Warner Chappell/, 'WC');
				if (!cats[seed.catalog.name]) cats[seed.catalog.name] = 0;
				cats[seed.catalog.name] += 100;
			}

			// get the total percent for thtis key
			const total = Object.values(cats).reduce((acc, val) => acc + val, 0);
			// normalize the percents so they add up to 50
			for (const [cat, percent] of Object.entries(cats)) {
				cats[cat] = Math.max(1, Math.round(percent / total * 50));
			}

			// add back to rights object
			rights[key] = Object.entries(cats);
		}
		
		console.log('- rights:', rights);
		setRights(rights);
	}

	function start() {
		if (!prompt) return;
		setStarted(true);
	}

	function promptChange(selected) {
		console.log('- setting prompt to:', selected);
		setPrompt(selected.value);
		setStarted(false);
	}

	return (
		<div className={'demo-page' + (pageWidth < 769 ? ' mobile' : '')}>
			<div className="example">
				<b>Example Use Case</b>: Pro end user needs to generate a new, on-trend and unique song for a film or TV trailer they plan to release commercially.
			</div>
			<div id="prompt-section" className="section" style={{
				top: positions.prompt.y,
				left: positions.prompt.x,	
			}}>
				<div className="section-title">Prompt</div>
				<div className="section-content">
					<Select
						styles={{
							control: (base, state) => ({
								...base,
								borderColor: '#000',
							})
						}}
						theme={(theme) => ({
							...theme,
							colors: {
								...theme.colors,
								primary: '#999',
							}
						})}
						onChange={promptChange}
						selectedOption={prompts[0]}
						options={prompts}
					/>
					{loaded ? <FontAwesomeIcon onClick={start} icon={faPlay} size="2x" fixedWidth /> : <FontAwesomeIcon icon={faRefresh} size="2x" className="fa-spin" fixedWidth />}
				</div>
			</div>
			{!started && loaded && (
				<>
					{!prompt && (
						<div id="choose-prompt-arrow" className="arrow" style={{
							top: positions.choosePrompt.y,
							left: positions.choosePrompt.x,	
						}}>
							<Arrow width={positions.choosePrompt.size} height={positions.choosePrompt.size} angle={positions.choosePrompt.angle} duration={500 * speedUp} delay={positions.choosePrompt.delay} title="Choose a Prompt" />
						</div>
					)}
					<div id="press-this-arrow" className="arrow" style={{
						top: positions.pressThis.y,
						left: positions.pressThis.x,	
					}}>
						<Arrow width={positions.pressThis.size} height={positions.pressThis.size} angle={positions.pressThis.angle} duration={500 * speedUp} delay={positions.pressThis.delay} title={(prompt ? '' : 'Then ') + 'Press This'} />
					</div>
				</>
			)}
			{started && loaded && (
				<>
					<div id="prompt-to-seed-arrow" className="arrow" style={{
						top: positions.promptToSeed.y,
						left: positions.promptToSeed.x,	
					}}>
						<Arrow width={positions.promptToSeed.size} height={positions.promptToSeed.size} angle={positions.promptToSeed.angle} duration={1000 * speedUp} delay={positions.promptToSeed.delay} title="Refine Dataset" />	
					</div>
					<div id="seed-section" className="section" style={{
						top: positions.seed.y,
						left: positions.seed.x,
					}}>
						<Seeds delay={positions.seed.delay} seedTracks={data[prompt].seed_tracks} />
					</div>
					<div id="seed-to-midi-arrow" className="arrow" style={{
						top: positions.seedToMidi.y,
						left: positions.seedToMidi.x,
					}}>
						<Arrow width={positions.seedToMidi.size} height={positions.seedToMidi.size} angle={positions.seedToMidi.angle} duration={1000 * speedUp} delay={positions.seedToMidi.delay} title="Composition" />
					</div>
					<div id="midi-section" className="section" style={{
						top: positions.midi.y,
						left: positions.midi.x,
					}}>
						<Midi url={"/images/midi_tracks.svg"} delay={positions.midi.delay} title="Generated Composition" />
					</div>
					<div id="seed-to-render-arrow" className="arrow" style={{
						top: positions.seedToRender.y,
						left: positions.seedToRender.x,
					}}>
						<Arrow width={positions.seedToRender.size} height={positions.seedToRender.size} angle={positions.seedToRender.angle} duration={1000 * speedUp} delay={positions.seedToRender.delay} title="Instrument Data" />
					</div>
					<div id="midi-to-audio-arrow" className="arrow" style={{
						top: positions.midiToAudio.y,
						left: positions.midiToAudio.x,
					}}>
						<Arrow width={positions.midiToAudio.size} height={positions.midiToAudio.size} angle={positions.midiToAudio.angle} duration={1000 * speedUp} delay={positions.midiToAudio.delay} title="Rendering" />
					</div>
					<div id="audio-section" className="section" style={{
						top: positions.audio.y,
						left: positions.audio.x,
					}}>
						<AudioPlayer delay={positions.audio.delay} title="Generated Song w/ Full Instruments" url={data[prompt].audio_url} />
					</div>
					<div id="seed-to-rights-arrow" className="arrow" style={{
						top: positions.seedToRights.y,
						left: positions.seedToRights.x,
					}}>
						<Arrow width={positions.seedToRights.size} height={positions.seedToRights.size} angle={positions.seedToRights.angle} duration={500 * speedUp} delay={positions.seedToRights.delay} title="Rights Data" />
					</div>
					<div id="rights-section" className="section" style={{
						top: positions.rights.y,
						left: positions.rights.x,
					}}>
						<Rights delay={positions.rights.delay} rights={rights[prompt]} />
					</div>
				</>
			)}
		</div>
	);
}