import './authPage.less';
import { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../../js/models/auth';

export function AuthPage() {
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const formRef = useRef(null);
	const auth = useAuth();

	async function onSubmit(e) {
		e.preventDefault();
		if (loading) return;

		setLoading(true);
		const formData = new FormData(formRef.current);
		const data = {
			code: formData.get('code'),
			email: formData.get('email'),
		};

		const r = await auth.login(data.code, data.email);
		if (r.error) setErrorMessage( r.error );
		setLoading(false);
	}

	return (
		<div id="auth-page">
			<div className="login-holder">
				<div id="musaic-logo">
					<img src="/images/Logo.svg" />
				</div>
				<form id="login" ref={formRef} onSubmit={onSubmit}>
					<input name="code" type="text" placeholder="Code" />
					<input name="email" type="email" placeholder="Email" />
					{errorMessage && <div className="error">{errorMessage}</div>}
					<button className="submit" type="submit">
						{loading ? <FontAwesomeIcon icon={faRefresh} className="fa-spin" /> : 'Login'}
					</button>
				</form>
			</div>
		</div>
	);
}