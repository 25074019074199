import { useEffect, useRef } from 'react';
import anime from 'animejs';

export function Seeds({ delay, seedTracks }) {

	const baseUrl = 'https://dm09pscvq9wc9.cloudfront.net';

	useEffect(() => {
		// set start position
		anime({
			targets: '.seed',
			scale: .1,
			opacity: 0,
			duration: 0,
		});

		setTimeout(() => {

			// animate in
			anime({
				targets: '.seed',
				scale: 1,
				opacity: 1,
				duration: 1000,
				delay: anime.stagger(100),
				complete: () => {
					
				}
			});
			anime({
				targets: '.section-title',
				opacity: 1,
				duration: 1000,
			});
		}, delay || 0);
	}, []);

	return (
		<>
			<div className="section-title">Seed Tracks</div>
			<div className={'seeds' + (seedTracks.length < 9 ? ' less' : '')}>
				{seedTracks.map((seed, i) => (
					<div key={'seed' + i} className="seed">
						<div className="seed-art"><img src={seed.album.image}  /></div>
					</div>
				))}
			</div>
		</>
	);
}