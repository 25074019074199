import { useEffect, useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons';

export function MainLayout() {
	const [pageWidth, setPageWidth] = useState(window.innerWidth);
	const [isNavOpen, setIsNavOpen] = useState(false);

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		}
	}, []);

	function handleResize() {
		setPageWidth(window.innerWidth);
	}

	function handleNavToggle() {
		setIsNavOpen(!isNavOpen);
	}

	function handleNavLinkClick() {
		setIsNavOpen(false);
	}

	return (
		<div id="main-layout" className={(pageWidth < 769 ? 'mobile' : '')}>
			<div id="header">
				<NavLink to="/" id="musaic-logo" relative="path">
					<img src="/images/Logo.svg" />
				</NavLink>
				<input type="checkbox" id="nav-toggle" className="nav-toggle" checked={isNavOpen} onChange={handleNavToggle} />
				<label htmlFor="nav-toggle" className="nav-toggle-label">
					<FontAwesomeIcon icon={faBars} size="3x" fixedWidth />
				</label>
				<div className="nav">
					<NavLink className="nav-item" to="/demo" relative="path" onClick={handleNavLinkClick}>Attribution</NavLink>
					{/* <NavLink className="nav-item" to="/derivative" relative="path" onClick={handleNavLinkClick}>Derivative</NavLink> */}
					<NavLink className="nav-item" to="/adaptive" relative="path" onClick={handleNavLinkClick}>Adaptive</NavLink>
					<NavLink className="nav-item" to="/translation" relative="path" onClick={handleNavLinkClick}>Translation</NavLink>
					<NavLink className="nav-item" to="/audioguard" relative="path" onClick={handleNavLinkClick}>AudioGuard</NavLink>
					<a className="nav-item" href="https://aitags.sourceaudio.com/" onClick={handleNavLinkClick}>AI Tagging</a>
					<a className="nav-item" href="https://dreamworksefforts.sourceaudio.com/" onClick={handleNavLinkClick}>AudioGenius</a>
				</div>
			</div>
			<Outlet />
		</div>
	);
}
