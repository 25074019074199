import { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPause, faPlay, faDownload } from '@fortawesome/free-solid-svg-icons'
import { AudioPlayer } from './audioplayer';

export function DerivativeAlbum({ album, tracks, pageWidth }) {
	const baseUrl = 'https://dm09pscvq9wc9.cloudfront.net/';

	return (
		<div id="derivative-album" >
			<div className="section">
				<div className="section-title">Seed Album and Tracks</div>
				<div className="section-content">
					<div id={'album_' + album.id} className="derivative-album">
						<div className="album_art">
							<img src={baseUrl + (album.largeImage || album.image)} />
							<div className="album_info">
								<div><b>Album:</b> STYE1025 Bubble Pop</div>
								<div><b>Artist:</b> Moontribe</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="derivative-album-tracks">
			{tracks.map((track, i) => (
				<div className="derivative-album-track section" key={'track' + i}>
					<AudioPlayer title={track.title} key={'track' + i} url={track.audio_url} width={pageWidth < 769 ? 300 : 430} height={60} autoplay={false} />
				</div>
			))}
			</div>
		</div>
	);
}